export default function authHeader(api_endpoint = false,) {

  let user = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem('jwt_token');

  if (user && user.token) {
    return { 
      Authorization: "Bearer " + user.token,
      "Content-Type": "multipart/form-data",
      "responseType": api_endpoint,
     };
  } else {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
      "responseType": api_endpoint,
    };
  }
}