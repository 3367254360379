const staySignedIn = JSON.parse(localStorage.getItem("keep_signed_in"));
const initialState = staySignedIn
  ? { keep_signed_in: true }
  : { keep_signed_in: false };

export const keepSignedIn = {
  namespaced: true,
  state: initialState,
  actions: {
    keepSign({ commit }, status) {
      if (status) {
        commit("keepSign");
        localStorage.setItem("keep_signed_in", true);
      } else {
        commit("keepSignFailed");
        localStorage.setItem("keep_signed_in", false);
      }
    },
  },
  mutations: {
    keepSign(state) {
      state.keep_signed_in = true;
    },
    keepSignFailed(state) {
      state.keep_signed_in = false;
    },
  },
};