import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

initialState["userVerifyData"] = null;

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    //Layer
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },

    //Layer
    logout({ commit }) {
      //AuthService.logout();
      commit("logout");
    },

    //layer
    storeUserVerifyData({ commit }, user) {
      commit("setUserVerifyData", user);
    },
  },
  mutations: {

    //Layer
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },

    //Layer
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },

    //Layer
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      localStorage.removeItem("user");
    },

    //Layer
    setUserVerifyData(state, user) {
      localStorage.setItem("userVerifyData", JSON.stringify(user));
      state.userVerifyData = user;
    },
  },
};