import axiosInstance from "@/plugins/HTTP";
import authHeader from "./auth-header";

class AdminService {
  getProfile() {
    return axiosInstance.get("profile", {
      headers: authHeader(),
    });
  }
  getRatePlan() {
    return axiosInstance.get("rate-plan-details", {
      headers: authHeader(),
    });
  }
  getPinList(params) {
    let queryString = "";
    if (params) {
      queryString = "?" + params;
    }
    return axiosInstance.get("pins" + queryString, {
      headers: authHeader(),
    });
  }

  getVpnServerName(form) {
    return axiosInstance.post("get-vpnserver-names", form, {
      headers: authHeader(),
    });
  }

  getPanelSetting() {
    return axiosInstance.get("panel-setting", {
      headers: authHeader(),
    });
  }

  getResetUdid(pin_id){
    return axiosInstance.get("reset-udid/" + pin_id, {
      headers: authHeader(),
    });
  }

  bulkPinOperation(form) {
    return axiosInstance.post("bulk-pin-operation", form, {
      headers: authHeader(),
    });
  }

  resetPassword(form){
    return axiosInstance.post("reset-password", form, {
      headers: authHeader(),
    });
  }

  getDeviceDetails(id){
    return axiosInstance.get("device-details?id=" + id, {
      headers: authHeader(),
    });
  }

  getBandwidthConsumptions(username){
    return axiosInstance.get("user-bandwidth-consumption?username=" + username,{
      headers: authHeader(),
    })
  }

  showSubscriberPass(form){
    return axiosInstance.post("show-pin-pass", form, {
      headers: authHeader(),
    });
  }

  removeUser(data){
    return axiosInstance.delete(`pins/${data.id}`,{
      headers: authHeader(),
    })
  }

  getRatePlanDropdownData(){
    return axiosInstance.get("rate-plan-dropdown-data", {
      headers: authHeader(),
    });
  }

  storeSubscriber(form){
    return axiosInstance.post("pins", form, {
      headers: authHeader(),
    });
  }

  getRatePlanById(id){
    return axiosInstance.get("get-rates-by-plan-id/" + id,{
      headers: authHeader(),
    })
  }

  getRatePlans(){
    return axiosInstance.get("get-rates-plan",{
      headers: authHeader(),
    })
  }

  getCountries(){
    return axiosInstance.get("get-countries",{
      headers: authHeader(),
    })
  }

  getEditPinList(id){
    return axiosInstance.get("pins/" + id,{
      headers: authHeader(),
    })
  }
  editSubscriber(id, form){
    return axiosInstance.post("pins/"+ id, form, {
      headers: authHeader(),
    });
  }

  addOrEditSubscriberBatch(querystr, form){
    return axiosInstance.post("batch-pin-generate" + querystr, form, {
      headers: authHeader(),
    });
  }

  addOrEditReseller(querystr, form){
    return axiosInstance.post("resellers" + querystr, form, {
      headers: authHeader(),
    });
  }

  getSubscriberBatchList(){
    return axiosInstance.get("batches", {
      headers: authHeader(),
    });
  }

  getServerList(){
    return axiosInstance.get("server-list", {
      headers: authHeader(),
    });
  }

  batchPinActivation(form){
    return axiosInstance.post("batch-pin-activation", form, {
      headers: authHeader(),
    });
  }

  totalServerConnectionChart(form){
    return axiosInstance.post("dashboard/total-server-connection-chart", form, {
      headers: authHeader(),
    });
  }

  subscriberPinsChartData(form){
    return axiosInstance.post("dashboard/subscribed-pins-chart-data", form, {
      headers: authHeader(),
    });
  }

  batchPinDownload(id) {
    return axiosInstance.get("batch-pin-download/" + id,{
      headers: authHeader(),
    })
  }

  getSingleBatchById(id) {
    return axiosInstance.get("batches/" + id,{
      headers: authHeader(),
    })
  }

  updateSingleBatchById(id, form) {
    return axiosInstance.post("batches/"+ id, form, {
      headers: authHeader(),
    });
  }

  getSubscriberBatchByQueryStr(querystr){
    return axiosInstance.get("batches?" + querystr, {
      headers: authHeader(),
    });
  }

  connectedUser(form){
    return axiosInstance.post("connected-users", form, {
      headers: authHeader(),
    });
  }

  loadDashboardPinCOuntData(form){
    return axiosInstance.post("dashboard/load-dashboard-pin-count-data", form, {
      headers: authHeader(),
    });
  }

  removeServer(data){
    return axiosInstance.delete(`ips/${data.id}`,{
      headers: authHeader(),
    })
  }

  wireguardUserConDownload(form){
    return axiosInstance.post("wireguard-user-con-download", form, {
      headers: authHeader(),
    });
  }

  getServer(params){
    let queryString = "";
    if (params) {
      queryString = "?" + params;
    }
    return axiosInstance.get("ips" + queryString, {
      headers: authHeader(),
    });
  }

  getCountry(){
    return axiosInstance.get("get-ip-countries", {
      headers: authHeader(),
    });
  }

  getEditServer(id){
    return axiosInstance.get("ips/" + id,{
      headers: authHeader(),
    })
  }

  addOrEditServer(querystr, form){
    return axiosInstance.post("ips" + querystr, form, {
      headers: authHeader(),
    });
  }

  removeServerBudle(data){
    return axiosInstance.delete(`ip-bundles/${data.id}`,{
      headers: authHeader(),
    })
  }

  getServerBundle(params){
    let queryString = "";
    if (params) {
      queryString = "?" + params;
    }
    return axiosInstance.get("ip-bundles" + queryString, {
      headers: authHeader(),
    });
  }

  getEditServerBundle(id){
    return axiosInstance.get("ip-bundles/" + id,{
      headers: authHeader(),
    })
  }

  addOrEditServerBunndle(querystr, form){
    return axiosInstance.post("ip-bundles" + querystr, form, {
      headers: authHeader(),
    });
  }

  getServerBundleDetails(id){
    return axiosInstance.get("ip-bundle-details/" + id,{
      headers: authHeader(),
    })
  }
  removeServerBundleDetails(data){
    return axiosInstance.delete(`ip-bundle-details/${data.id}`,{
      headers: authHeader(),
    })
  }

  editServerBundleDetails(id, form){
    return axiosInstance.post("ip-bundle-details/" + id, form, {
      headers: authHeader(),
    });
  }

  addServerBundleDetails(form){
    return axiosInstance.post("ip-bundle-details", form, {
      headers: authHeader(),
    });
  }

  addRateplan(form){
    return axiosInstance.post("rate-plan", form, {
      headers: authHeader(),
    });
  }

  radiusServerProvisioning(form){
    return axiosInstance.post("radius-server-provisioning", form, {
      headers: authHeader(),
    });
  }

  addRatePlanDetails(form){
    return axiosInstance.post("rate-plan-details", form, {
      headers: authHeader(),
    });
  }

  ovpnProvisioning(form){
    return axiosInstance.post("ovpn-provisioning", form, {
      headers: authHeader(),
    });
  }

  getEditRatePlan(id){
    return axiosInstance.get("rate-plan/" + id,{
      headers: authHeader(),
    })
  }
  editRatePlan(id, form){
    return axiosInstance.post("rate-plan/" + id, form,{
      headers: authHeader(),
    })
  }

  getRatePlanDetails(rate_id, plan_id){
    return axiosInstance.get('rate-plan-details/'+rate_id+'?rate_plan_id='+plan_id,{
      headers: authHeader(),
    })
  }

  editRatePlanDetails(id, form){
    return axiosInstance.post("rate-plan-details/" + id, form,{
      headers: authHeader(),
    })
  }

  saveVpnServer(id, form){
    return axiosInstance.post("vpn-servers" + id, form,{
      headers: authHeader(),
    })
  }

  getRatePlanList(){
    return axiosInstance.get("rate-plan", {
      headers: authHeader(),
    });
  }

  getRatePlanByQueryStr(querystr){
    return axiosInstance.get("rate-plan?" + querystr, {
      headers: authHeader(),
    });
  }

  removeRatePlan(rate){
    return axiosInstance.delete(`/rate-plan/${rate.id}`,{
      headers: authHeader(),
    })
  }

  getRatePlanByPlanId(plan_id){
    return axiosInstance.get("get-rates-by-plan-id/" + plan_id,{
      headers:authHeader(),
    })
  }

  getVpnServer(id){
    return axiosInstance.get("vpn-servers/" + id,{
      headers:authHeader(),
    })
  }

  getRefreshToken(){
    return axiosInstance.get("refresh-token", {
      headers: authHeader(),
    });
  }

  getResellers(){
    return axiosInstance.get("get-reseller-dropdown-data", {
      headers: authHeader(),
    });
  }

  getIpBundleDropdown(){
    return axiosInstance.get("get-ip-bundle-dropdown", {
      headers: authHeader(),
    });
  }

  getSettings(){
    return axiosInstance.get("settings", {
      headers: authHeader(),
    });
  }

  getCities(){
    return axiosInstance.get("get-cities", {
      headers: authHeader(),
    });
  }

  saveAppConfig(form){
    return axiosInstance.post("app-config-save", form, {
      headers: authHeader(),
    });
  }

  /*start of push app*/
  addOrEditOneSignalApp(querystr, form){
    return axiosInstance.post("onesignal-apps" + querystr, form, {
      headers: authHeader(),
    });
  }

  addOrEditRadiusServer(querystr, form){
    return axiosInstance.post("radius-auth-server" + querystr, form, {
      headers: authHeader(),
    });
  }

  removeOneSignalApp(data){
    return axiosInstance.delete(`onesignal-apps/${data.id}`,{
      headers: authHeader(),
    })
  }

  getOneSignalAppByQueryStr(querystr){
    return axiosInstance.get("onesignal-apps?" + querystr, {
      headers: authHeader(),
    });
  }

  getVpnServersByQueryStr(querystr){
    return axiosInstance.get("vpn-servers?" + querystr, {
      headers: authHeader(),
    });
  }

  getRadiusServerByQueryStr(querystr){
    return axiosInstance.get("radius-auth-server?" + querystr, {
      headers: authHeader(),
    });
  }

  getResellerListByQueryStr(querystr){
    return axiosInstance.get("resellers?" + querystr, {
      headers: authHeader(),
    });
  }

  resellerStatusUpdate(id, form){
    return axiosInstance.post("resellers/" + id, form,{
      headers: authHeader(),
    })
  }

  updatePannelSetting(id, form){
    return axiosInstance.post("update-panel-setting/" + id, form,{
      headers: authHeader(),
    })
  }

  rechargeByResellerIdAndRatePlanId(reseller_id,rate_plan_id){
    return axiosInstance.get("recharge/" + reseller_id + "?rate_plan_id=" + rate_plan_id, {
      headers: authHeader(),
    });
  }

  saveRecharge(form){
    return axiosInstance.post("recharge", form, {
      headers: authHeader(),
    });
  }

  getOneSignalAppList(){
    return axiosInstance.get("onesignal-apps", {
      headers: authHeader(),
    });
  }

  editOneSignalApp(id){
    return axiosInstance.get("onesignal-apps/" + id,{
      headers: authHeader(),
    })
  }

  deleteVpnServer(id){
    return axiosInstance.delete("vpn-servers/" + id,{
      headers: authHeader(),
    })
  }

  editRadiusAuthServer(id){
    return axiosInstance.get("radius-auth-server/" + id,{
      headers: authHeader(),
    })
  }

  editSingleReseller(id){
    return axiosInstance.get("resellers/" + id,{
      headers: authHeader(),
    })
  }
  /*end of push app*/

  getApps(){
    return axiosInstance.get("get-app-dropdown-data", {
      headers: authHeader(),
    });
  }
  /*start of push template*/
  getPushTemplateList(){
    return axiosInstance.get("push-template", {
      headers: authHeader(),
    });
  }
  removePushTemplate(data){
    return axiosInstance.delete(`push-template/${data.id}`,{
      headers: authHeader(),
    })
  }
  getPushTemplateByQueryStr(querystr){
    return axiosInstance.get("push-template?" + querystr, {
      headers: authHeader(),
    });
  }
  addOrEditPushTemplate(querystr, form){
    return axiosInstance.post("push-template" + querystr, form, {
      headers: authHeader(),
    });
  }

  getEditPushTemplate(id){
    return axiosInstance.get("push-template/" + id,{
      headers: authHeader(),
    })
  }
  /*end of push template*/

  /*start of push request*/
    getPushRequestList(){
      return axiosInstance.get("push", {
        headers: authHeader(),
      });
    }

    approvePushRequest(form){
      return axiosInstance.post("push-approval", form, {
        headers: authHeader(),
      });
    }

  getTemplates(){
    return axiosInstance.get("get-template-dropdown-data", {
      headers: authHeader(),
    });
  }

  getBundleDropDown(){
    return axiosInstance.get("rate-plan-dropdown-data", {
      headers: authHeader(),
    });
  }

  addOrEditPush(querystr, form){
    return axiosInstance.post("push" + querystr, form, {
      headers: authHeader(),
    });
  }

  getPushRequestByQueryStr(querystr){
    return axiosInstance.get("push?" + querystr, {
      headers: authHeader(),
    });
  }
    /*start of push request*/

  addSubscriberSubscriptionReport(form){
    return axiosInstance.post("reports/pin-subscription", form, {
      headers: authHeader(),
    });
  }

  vpnServerConnections(form){
    return axiosInstance.post("reports/vpn-server-connections", form, {
      headers: authHeader(),
    });
  }

  searchReportData(form){
    return axiosInstance.post("reports/pin-subscription", form, {
      headers: authHeader(),
    });
  }

  searchOnlineTransactionReport(form){
    return axiosInstance.post("reports/web-transaction-report", form, {
      headers: authHeader(),
    });
  }
}

export default new AdminService();
