<template>
    <div @mousedown="com_SetLastActivityTime">
        <router-view />
    </div>
</template>

<script>
export default {
  
}
</script>
